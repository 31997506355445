import React from 'react';
import {Helmet} from "react-helmet-async";

import Header from './components/Header/index';
import Footer from './components/Footer/index';
import MobileMenu from './components/MobileMenu/index';
import ContactForm from './components/ContactForm/index';
import {NavLink} from 'react-router-dom';
import API_CALLS from 'API_CALLS/index';
import APISaveForTracking, {APISaveForTrackingUnique} from 'API_CALLS/TRACKING/TRACKING_FUNCTIONS';
import 'styles/scss/website/pages/customLandingPage.scss';
//import Compare from "../bestAligners/compare.js";
import before_after_combined from 'global/img/before_after_combined_5-min.png';
//import before_after_combined from 'global/img/CircleAligner_1000-min.png';
///Users/danielezaldivar/Sites/smiles_club/src/styles/scss/website/components/simpleStepsList.scss

import TreatmentPlanBar from 'website/components/TreatmentPlanBar/index';
import SimpleStepsList from './components/SimpleSteps';
import CONTENTS from "./CONTENTS";
//EXAMPLE: http://localhost:3000/deals001?CODE=SUMMERDEAL3

import LayoutBuilder from 'LayoutBuilder';

const {Container, Row, Column} = LayoutBuilder;
class Index extends  React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      loading: true,
      CODE: undefined
    };    
    this.ShowContactFormKey = window.GlobalUtil.subscribeEvent("ShowContactForm", showOrNot=>{
      this.setState({showContactForm: showOrNot});
    });
  }

  componentDidMount = () => {    
    this.RunAPIOnceWhenReady = window.GlobalUtil.runAPIOnceWhenReady(this.getURLCode);
    //APISaveForTrackingUnique({page: "CUSTOM_LANDING_1", url: "/deals001", type: "PAGE"});
    this.onScroll();
    //if(!window.Client) console.log("No Client Yet")
  }

  componentWillUnmount(){
    this.RunAPIOnceWhenReady.unsubscribe();
    this.ShowContactFormKey.unsubscribe();
    //if(this.DelayPointer) this.DelayPointer.abort(); //IF WENT BACK BEFORE FOUND THEN CANCEL ANY API SEARCH
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      //console.log(`this.curPage`,"\n\n",this.curPage,"\n\n");        
      window.GlobalUtil.triggerEvent("PageChangedEvent", this.curPage);
    }
  }


  getURLCode = () => {    
    var queryString = this.props.location.search;
    var urlParams = new URLSearchParams(queryString);
    var CODE = urlParams.get('CODE');
    
    if(CODE){
      if(CODE.slice(-1) === "/") CODE = CODE.slice(0,-1);
      //console.log(`CODE`,"\n\n",CODE,"\n\n");
      var newRefCode = CODE.split("-").join("")
      this.setState({
        CODE: newRefCode
      }, this.onCheckReferral); 
    } else {
      window.GlobalUtil.removeCoupon().then(()=>{
        this.setState({loading: false});
      });
    }
  }


  onCheckReferral = () => {
    var {CODE} = this.state;
    if(!CODE) return this.setState({ loading: false});
    this.setState({ loading: true});
    window.Client.query({
      query: API_CALLS.COUPONS.QUERIES.couponFinder(),
      variables: {
        "code": CODE
      }
    })
    .then(async ({data={}}) => {              
      var statusMessage = "success";
      //console.log('onCheckReferral data', data);
      //console.log(`data`,"\n\n",data,"\n\n");
      if(!data.couponFinder || !data.couponFinder._id) {
        statusMessage = "failed";
      }
      var coupon = ((statusMessage !== "failed") ? window.GlobalUtil.APICleanObj(data.couponFinder) : undefined);              
      window.GlobalUtil.consoleLogNew({LOCATION:`src/website/pages/customLandingPages/page1`, NAME:`WEBSITE -> PAGE -> BENEFITS -> ReferralCodeSearch -> onCheckReferral() -> SUCCESS`, CONTENT:coupon});
      window.GlobalUtil.LocalStorage.set("CustomPageCoupon", true);
      await window.GlobalUtil.setCoupon(coupon);
      if(this.state) this.setState({ loading: false});
    })
    .catch(async (error)=>{
      let errorMessage = error.message;
      window.GlobalUtil.consoleLogNew({LOCATION:`src/website/pages/customLandingPages/page1`, NAME:`WEBSITE -> PAGE -> BENEFITS -> ReferralCodeSearch -> onCheckReferral() -> ERROR`, CONTENT:error});
      await console.log("onSubmitLogin errorMessage");
      await console.dir(errorMessage,"\n\n");
      await console.dir(error,"\n\n");
      await window.GlobalUtil.removeCoupon();
      if(this.state) this.setState({ loading: false});
      //if(errorMessage === "Unexpected error value: \"CODE NOT FOUND\"")
    });
  }


  onScroll = () => {
    if(!window) return;
    if(!this.props.location || !this.props.location.hash) return;
    if(!document.querySelector(this.props.location.hash)) return;
    var top = document.querySelector(this.props.location.hash).scrollHeight;
    window.scrollTo({
      top: top,
      left: 0,
      //behavior: 'instant'
    });   
  }



  render(){
    var {showSideMenu, loading, showContactForm} = this.state;
    const CONTENT = window.GlobalUtil.dangerSetGlobalObjList(CONTENTS[window.Session.Language].CONTENT); 
    const HEADER = CONTENTS[window.Session.Language].HEADER; 
    var pathname = window.location.pathname;
    if(this.props.location.search) pathname += this.props.location.search;
    
    
    if(pathname.slice(-1) ==="/") pathname = pathname.slice(0,-1);
    
    const CleanLinks=[
      {
        "PageName":"Home",
        "Name":"Home",
        "Paths": [`${pathname}`],
        "Hash": null
      },
      {
        "PageName":"Full Treatment",
        "Name":"Full Treatment",
        "Paths":  [`${pathname}/#TreatmentPlanBar`],
        "Hash":"#TreatmentPlanBar"
      },    
      {
        "PageName":"Steps",
        "Name":"Steps",
        "Paths": [`${pathname}/#StepsZone`],
        "Hash": "#StepsZone"
      },
    ];     
    return (
      <div id="Website" className="customLandingPages">
        {
          showSideMenu && <MobileMenu onHideSideMenu={()=>this.setState({showSideMenu: false})} CleanLinks={CleanLinks}/>
        }
        <Header 
          onShowSideMenu={()=>this.setState({showSideMenu: true})}
          onClickLink={()=>{}}
          CleanLinks={CleanLinks}
          homeLink={pathname}
        />
        <div id="Content">
          <div className="contentPage" id="CustomLandingPage">        
            <Helmet>
                <meta charSet="utf-8" />
                <title>{HEADER.TITLE}</title>
                <meta name="description" content={HEADER.DESCRIPTION}/>
                <link  href={`${window.location.pathname}`} />
                <link rel="canonical" href={`${HEADER.BASEURL}${window.location.pathname}`} />
                <meta name="keywords" content={HEADER.KEYWORDS} />

                {"<!-- Google / Search Engine Tags -->"}
                <meta itemprop="name" content={HEADER.TITLE} />
                <meta itemprop="description" content={HEADER.DESCRIPTION}/>
                <meta itemprop="image" content="https://Smiles.Club/SocialImage_500.jpg" />

                {/*<!-- Facebook Meta Tags -->*/}
                <meta property="og:url" content={`${HEADER.BASEURL}${window.location.pathname}`} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={HEADER.TITLE} />
                <meta property="og:description" content={HEADER.DESCRIPTION}/>
                <meta property="og:image" content="https://Smiles.Club/SocialImage_500.jpg" />

                {/*<!-- Twitter Meta Tags -->*/}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={HEADER.TITLE} />
                <meta name="twitter:description" content={HEADER.DESCRIPTION}/>
                <meta name="twitter:image" content="https://Smiles.Club/SocialImage_500.jpg" />
            </Helmet>
            <div id="ProductTop" style={{"display":"none"}}></div>
            <div className="headerZone">
              <Container id="BuyItToday">
                <Row>
                  <Column className="contentCenter topTextZone" col="" xs="" sm="" md="" lg="" xl="">
                    <div className="textZoneWrapper">
                      <img 
                        className="stepImages d-block d-lg-none" 
                        id="StepImages1"
                        src={before_after_combined} 
                        alt="women researching clear aligners on her laptop"
                      />
                      <div className="preHeader">Discover your True Smile</div>
                      <h1 className="sectionTitle">Your new Smile is closer than you think. Get your Clear Aligners <br/><strong>NOW and pay it off in 6-36 payments with Affirm!</strong></h1>
                      <div className="sectionText">Time and flexibility are on your side. With Affirm, pay at a pace that works for you. Up to 36-month payment plans with no credit check.</div>
                    </div>
                  </Column>
                  <Column className="d-none d-lg-block" col="" xs="" sm="" md="" lg="" xl="">
                    <div className="beforeAfter">
                      <img 
                        className="stepImages d-none d-lg-block" 
                        id="StepImages1"
                        src={before_after_combined} 
                        alt="women researching clear aligners on her laptop"
                      />
                    </div>
                  </Column>
                </Row>
              </Container>
            </div>
            {CONTENT.PageBuyImpressionKit[0]}
            {
              loading
              ? null
              : <TreatmentPlanBar showCouponInTreatmentPlanBar={true} showAreYouSurePopupDialog={false} onClickAddToCart={()=>APISaveForTracking({page: "CUSTOM_LANDING_1", url: "/deals001", type: "ADD_TO_CART"})} API_ref={"TREATMENT_PLAN1"}/>
            }
            <Container id="SimpleStepsHeaderContainer" className="simpleStepsHeader">
              <Row className="">
                <Column className="text-center" col="" xs="" sm="" md="" lg="" xl="">
                  {CONTENT.SimpleStepsHeaderContainer[0]}
                </Column>
              </Row>
            </Container>
            <Container id="StepsZone">
              <Row>
                <Column className="" col="12" xs="12" sm="12" md="12" lg="12" xl="">
                  <SimpleStepsList 
                    CONTENT={CONTENT.SimpleStepsList}
                  />
                  <div className="buttonGroup center">
                    <NavLink className={"button button1"} id="BuyNowButton" to={`${pathname}/#TreatmentPlanBar`} onClick={()=>{
                        window.GlobalUtil.triggerEvent("PageChangedEvent", {
                          "PageName":"Home",
                          "Name":"Home",
                          "Paths": [`${pathname}`],
                          "Hash": null
                        });
                  }}>Buy Now</NavLink>
                  <button className={"button button2 btn-color5"} onClick={()=>window.GlobalUtil.triggerEvent("ShowContactForm", true)}>Need Help?</button>
                  </div>
                </Column>
              </Row>
            </Container>
            {/* <Compare/> */}
          </div>
        </div>
        <Footer onShowContact={()=>{if(this.HomeRef) this.HomeRef.onShowContact();}}/>
        {
          showContactForm && 
          <ContactForm 
            urlParams={{
              ...this.props.location, 
              "PageName":"Home",
              "Name":"Home",
              "Paths": [`${pathname}`]
            }}
            onClose={()=>{
              this.setState({showContactForm: false})
            }}
          />
        }
      </div>
    );
  }
}



// const Product = (props)=>{
//   var {product={}} = props;
//   return(
//     <div className="product productLayout1">
//       <div className="productImages d-none d-lg-block">
//         <div className="productImage">
//           <img src={window.GlobalUtil.deepGetFromString(product, "images,0,url", null)} alt=""/>
//         </div>
//       </div>
//       <div className="productText">
//         <div className="productImage d-block d-lg-none">
//           <img src={window.GlobalUtil.deepGetFromString(product, "images,0,url", null)} alt=""/>
//           <br/>
//         </div>
//         <div className="productTextName">
//           {product.name}
//         </div>
//         <div className="productTextCost d-flex">
//           <div className="cost">{window.GlobalUtil.convertToMoney(product.cost-product.discount)}</div>
//           <div className="originalPrice">{window.GlobalUtil.convertToMoney(product.cost)}</div>
//         </div>
//         <div className="productTextDesc">
//           {window.GlobalUtil.dangerSet(product.description)}
//         </div>
//         {/*<div className="">
//           Category: {product.category}
//         </div>*/}
//         <div className="buttonGroup">
//           <button className="button button2 btn-color5 dontCloseCart" onClick={()=>{
//             window.GlobalUtil.addToCart({product: product, replaceAll: true, onlyOne: true}, ()=>{
//               window.GlobalUtil.triggerEvent("cartEvent", "show");
//             });
//           }}>
//           {
//             (window.Session.Language === "ENGLISH")
//             ? "Buy Now"
//             : "Compra Ahora"
//           }
//           </button>
//         </div>
//       </div>
//     </div>
//   )
// }  

export default Index;
