import React from 'react';
import {CartContent} from 'global/components/Cart/index';
import MobileTotalButton from './MobileTotalButton';
//import Loading from 'global/components/Loading/index';


// function Index (props){
//   var {newOrder} = props;
//   return (
//     <div id="CheckoutPageComponentLeftSide">
//       <div id="CartContentWrapper">
//         {
//           window.screen.width > 991
//           ? <CartContent newOrder={newOrder} />
//           : null 
//         }
//         {
//           window.screen.width <= 991
//           ? <MobileTotalButton newOrder={newOrder} />
//           : null 
//         }
//       </div>
//     </div>
//   );
// }





class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showBig: true
    };    
    
  }

  componentDidMount(){
    this.setState({
      showBig: (window.innerWidth > 991 ) ? true : false
    });
    this.ResizeEventKey = window.GlobalUtil.subscribeEvent("ResizeEvent", (curWidth)=>{
      if(curWidth > 991) {
        this.setState({ //THIS WILL FORCE IT TO EXPAND
          showBig: true
        });
      } else {
        this.setState({ //THIS WILL FORCE IT TO EXPAND
          showBig: false
        });
      }
    });
  }

  componentWillUnmount(){
    this.ResizeEventKey.unsubscribe();
  }

  render(){
    var {newOrder} = this.props;
    var {showBig} = this.state;
    return (
      <div id="CheckoutPageComponentLeftSide">
        <div id="CartContentWrapper">
          {
            showBig
            ? <CartContent newOrder={newOrder} />
            : null 
          }
          {
            !showBig
            ? <MobileTotalButton newOrder={newOrder} />
            : null 
          }
        </div>
      </div>
    );
  }
}




export default Index;
      
