//import React from 'react';
const PAGE = {
  	"ENGLISH": {
		"CONTENT": {
			"FinancingWrapperTitle": [
				{
					//NUM 0
					"type": "div",
					"id": "FinancingWrapperTitle",
					"content": `
						<div id="FinancingWrapperTitle1">Need <strong>FINANCING?</strong></div>
        				<div id="FinancingWrapperTitle2">No Problem!</div>
					`
				}
			],
			"AffirmBlerb": [
				{
					"type": "div",
					"className": "info",
					"content": 'As low as <strong>$70/month</strong>*'
				},
				{
					"type": "div",
					"className": "smallText",
					"content": '*select <strong>affirm</strong> at checkout to see rates and offers.'
				}
			],
			"AfterpayBlerb": [
				{
					"type": "div",
					"className": "info",
					"content": 'As low as <strong>$169/month</strong>*'
				},
				{
					"type": "div",
					"className": "smallText",
					"content": '*select <strong>afterpay</strong> at checkout to see rates and offers.'
				}
			]
		}
	},
	"ESPAÑOL": {
		"CONTENT": {
			"FinancingWrapperTitle": [
				{
					//NUM 0
					"type": "div",
					"id": "FinancingWrapperTitle",
					"content": `
						<div id="FinancingWrapperTitle1">¿Necesita <strong>FINANCIACIÓN?</strong></div>
        				<div id="FinancingWrapperTitle2">¡No hay problema!</div>
					`
				}
			],
			"AffirmBlerb": [
				{
					"type": "div",
					"className": "info",
					"content": 'Tan solo <strong>$70/mes</strong>*'
				},
				{
					"type": "div",
					"className": "smallText",
					"content": '*seleccione <strong>affirm</strong> al finalizar la compra para ver tarifas y ofertas.'
				}
			],
			"AfterpayBlerb": [
				{
					"type": "div",
					"className": "info",
					"content": '<strong>$169/mes</strong>*'
				},
				{
					"type": "div",
					"className": "smallText",
					"content": '*seleccione <strong>afterpay</strong> al finalizar la compra para ver tarifas y ofertas.'
				}
			]
		}
	}
};


	
export default PAGE;


