import React from 'react';
import CONTENTS from "./CONTENTS";
import {CartContent, CartTotal} from 'global/components/Cart/index';



class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      marginTop: 0,
      withTooWide: undefined,
      floatingClass: undefined
    };    
    
  }

  componentDidMount(){
    this.setState({
      showBig: (window.innerWidth > 991 ) ? true : false
    });
    this.ResizeEventKey = window.GlobalUtil.subscribeEvent("ResizeEvent", this.windowResized);
  }

  componentWillUnmount(){
    this.ResizeEventKey.unsubscribe();
  }

  windowResized = (newSize) => {    
    if(newSize > 991) {
    }
  }

  toggleHeight = (forceActive) => { //TRUE WILL FORCE IT TO SHRINK, WHILE FALSE WILL FORCE IT TO EXPAND, WHILE NOTHING WILL HAVE IT TOGGLE
    var {active} = this.state;  
    if(active){
      return this.setState({
        //marginTop: ((active === false) ? `-${currentHeight - 60}px` : `-10px`),
        floatingClass: !active ? "active" : null
      }, ()=>{
        setTimeout(()=>{
          this.setState({
            active: !active //IF keepSame IS TRUE, THEN IT'S RESIZED AND JUST NEEDS TO CHANGE HEIGHT NOT RESET
          })
        }, 500)
      });
    }

    return this.setState({
      //marginTop: ((active === false) ? `-${currentHeight - 60}px` : `-10px`),
      active: !active //IF keepSame IS TRUE, THEN IT'S RESIZED AND JUST NEEDS TO CHANGE HEIGHT NOT RESET
    }, ()=>{
      setTimeout(()=>{
        this.setState({
          floatingClass: !active ? "active" : null
        })
      }, 5)
    });
    if((forceActive === true) || (forceActive === false)) active = !forceActive;
    if(this.CartContentWrapperRef){
      var currentHeight = this.CartContentWrapperRef.clientHeight;
      this.setState({
        marginTop: ((active === false) ? `-${currentHeight - 60}px` : `-10px`),
        active: !active //IF keepSame IS TRUE, THEN IT'S RESIZED AND JUST NEEDS TO CHANGE HEIGHT NOT RESET
      });
    } 
  }

  render(){
    var {newOrder} = this.props;  
    var {active, floatingClass} = this.state;  
    const CONTENT = (typeof window === 'undefined') ? CONTENTS["ENGLISH"] : window.GlobalUtil.dangerSetGlobalObjList(CONTENTS[window.Session.Language].CONTENT); 

    return (
      <div id="MobileTotalAndButton">
        <CartTotal newOrder={newOrder} />
        <div className="expandButton d-sm-block d-lg-none">
          <button className="button button1" onClick={this.toggleHeight}>
            Expand&nbsp;&nbsp;
            <i className={`fas fa-angle-double-down`}></i>
          </button>
        </div>
        {
          active
          ? <div id="FloatingCartContentWrapper">
              <div id="FloatingCartContent" className={floatingClass}>
                <CartContent newOrder={newOrder} />
                <div className="expandButton d-sm-block d-lg-none">
                  <button className="button button1 btn-color5" onClick={this.toggleHeight}>
                    Shrink&nbsp;&nbsp;
                    <i className={`fas fa-angle-double-up`}></i>
                  </button>
                </div>
              </div>
              <div id="BackgroundOverlay" onClick={this.toggleHeight}/>
            </div>
          : null
        }
      </div>
    );
  }
}




      // <div id="BlerbWrapper">
      //   <div ied="AffirmBlerb" className="blerbWrapper">
      //     <div className="blerb">
      //       <div className="logo"><img src={AffirmLogo} alt={"Affirm Logo"} /></div>
      //       {CONTENT["AffirmBlerb"][0]}
      //       {CONTENT["AffirmBlerb"][1]}
      //     </div>
      //   </div>
      //   <div id="AfterpayBlerb" className="blerbWrapper">
      //     <div className="blerb">
      //       <div className="logo"><img src={AfterpayLogo} alt={"Afterpay Logo"} /></div>
      //       {CONTENT["AfterpayBlerb"][0]}
      //       {CONTENT["AfterpayBlerb"][1]}
      //     </div>
      //   </div>
      // </div>






export default Index;
      
