import React from 'react';
import SidePayment from "./SidePayment";
import SideSummary from "./SideSummary";
import StripePayment from "global/components/StripePayment/index";
import PaypalButtons from 'global/components/PaypalButtons/index';
import PaypalButtonSubscription from 'global/components/PaypalButtonSubscription/index';
import TestCheckoutButton from 'global/components/TestCheckoutButton/index';
import DialogWrapper from 'global/components/DialogWrapper/index';
import {GetBasicUserInfoAsync} from 'global/utils/helper-functions';
import CONTENTS from "./CONTENTS";
import LayoutBuilder from 'LayoutBuilder';
import WithLocationHook from 'global/components/WithLocationHook/index';


const {Container, Row, Column} = LayoutBuilder;
//import Loading from 'global/components/Loading/index';
//import CheckoutPage from 'global/components/CheckoutPage/index';


class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: {}, 
      finalCheckoutCalculator: {},
      //Session: (window.GlobalUtil.State.get("Session") ? window.GlobalUtil.State.get("Session") : null),
      //newOrder: (window.GlobalUtil.State.get("newOrder") ? window.GlobalUtil.State.get("newOrder") : {products: [], coupon: {}}),
      Session:  null,
      newOrder:  {products: [], coupon: {}},
      showStripePayment: true, //false,
      keysPressed: []
    };    
    
    // this.TestSessionKey = window.GlobalUtil.subscribeEvent("testSession", newTest=>{
      //   
      //   window.TestSession = newTest;
      //   this.setState({date: new Date()})
      // })
    }
    
    componentDidMount(){
      this.setState({
        Session: (window.GlobalUtil.State.get("Session") ? window.GlobalUtil.State.get("Session") : null),
        newOrder: (window.GlobalUtil.State.get("newOrder") ? window.GlobalUtil.State.get("newOrder") : {products: [], coupon: {}}),
      })

      //if(window.Botsonic) window.Botsonic("hide");
      this.NewOrderSubKey = window.GlobalUtil.State.subscribe("newOrder", newOrd=>{
        this.setState({newOrder: newOrd})
      })
      this.NewSessionSubKey = window.GlobalUtil.State.subscribe("Session", newSession=>{
        this.setState({Session: newSession})
      })
      //this.keyDownRef = document.addEventListener('keydown', this.keyComboWatcher);
      //var newOrder = window.GlobalUtil.getCartFromLocalStorage();
      //console.log(`componentDidMount newOrder`,"\n\n",newOrder,"\n\n");
            
      // if(window.GlobalUtil.State.get("newOrder")){
      //   window.GlobalUtil.setCartToLocalStorage(window.GlobalUtil.State.get("newOrder"))
      // }
    }

    componentWillUnmount(){
      this.NewOrderSubKey.unsubscribe()
      this.NewSessionSubKey.unsubscribe()
      document.removeEventListener('keydown', this.keyDownRef);
      //this.TestSessionKey.unsubscribe();
    }


    // keyComboWatcher = (e) => {
    //   var {keysPressed, showStripePayment} = this.state;          
    //   //console.log('Key', e.code)
    //   const keysPressedKeys = ["ShiftLeft", "Digit1", "Digit2", "Digit3"];
    //   const keyPressed = e.code;
      
    //   if(showStripePayment) return; //IF UNLOCKED DON'T GO BACK

    //   if(keysPressedKeys.indexOf(keyPressed) === -1){ //IF KEY IS NOT IN LIST THEN RESTART
    //     keysPressed = [];
    //   } else if(keysPressedKeys[keysPressed.length] !== keyPressed){ //IF KEY IS NOT IN LIST THEN RESTART
    //     keysPressed = [];
    //   }  else {
    //     keysPressed.push(keyPressed);
    //   }

    //   if(keysPressed.join() === keysPressedKeys.join()){
    //     showStripePayment = true;
    //     console.log('IT WORKED!');
    //   }
    //   //console.log('keysPressed', keysPressed);
      
    //   this.setState({
    //     showStripePayment,
    //     keysPressed
    //   });
    // }



    onCloseSuccess=()=>{
      GetBasicUserInfoAsync({
        noCache: true,
        noGlobalUtilLogin: true //DON'T USE THE GLOBAL LOGIN UTIL, BECAUSE WE'LL USE IT BELOW
      })
      .then(obj=>{
        this.props.navigation((window.Session.Language === "ENGLISH") ? '/' : '/inicio');
      })
    }

    hideBotCrap = () => {
      //if(document.querySelector("#Botsonic-wrapper")){
      //  document.querySelector("#Botsonic-wrapper").classList.toggle("hidden", true)
      //}
    }

  render(){    
    var {curStatus, resetChildren, order={}, finalCheckoutCalculator={}, newOrder, Session, showStripePayment} = this.state;          
    //var {showSideMenu} = this.state;    
    const CONTENT = window.GlobalUtil.dangerSetGlobalObjList(CONTENTS[window.Session.Language].CONTENT); 
    this.hideBotCrap();
    if(resetChildren) return null;
    
    return (
      <div id="CheckoutPageComponent">
        <Container className="">
          <Row className="">
            <Column id="SideSummaryColumn" className="" col="" xs="" sm="" md="" lg="" xl="">
              <SideSummary
                newOrder={newOrder}
              />
            </Column>
            <Column className="customCheckoutRight" col="" xs="" sm="" md="" lg="" xl="">
              {
                // (Session && newOrder && newOrder.products && newOrder.products.length) &&
                // <div id="CheckoutPageComponentLeftSide">
                //   <div id="CheckoutSteps">
                //     <div id="CheckoutWrapper">
                //       {/* <StripePayment newOrder={newOrder} />  */}
                //     </div>
                //   </div>
                // </div>
              }
              <SidePayment 
                newOrder={newOrder}
                CONTENT={CONTENT}
                paypalButtons={()=>{
                  var newOrder = window.GlobalUtil.State.get("newOrder");
                  var paypalID = window.GlobalUtil.deepGetFromString(newOrder, "products,0,subscription,paypalID", false);
                  var basicType = window.GlobalUtil.deepGetFromString(newOrder, "products,0,type", false);
                  var checkoutCalculator = window.GlobalUtil.checkoutCalculator(newOrder.products, newOrder.coupon);
                  var PaypalButton = (((newOrder.products[0].type === "SUBSCRIPTION") && paypalID) ? PaypalButtonSubscription : PaypalButtons); //USE PAYPAL SUBSCRIPTION FOR SUBSCRIPTION PRODUCT, ELSE USE PAYPAL
                  if((basicType === "SUBSCRIPTION") && !paypalID) return(
                    <h5 className="">{CONTENT.CheckoutPageComponent[0]}</h5>
                  )    
                  //if(window.TestSession && (window.Session.user.role === "ADMIN")) 
                  if(process.env.NODE_ENV === 'development') PaypalButton = TestCheckoutButton;//IF ON TEST MODE THEN HAVE CHECKOUT BUTTON FOR EASY CHECKOUT TEST                        
                  if(showStripePayment) return(
                    <StripePayment newOrder={newOrder} /> 
                  )
                  return(
                    <PaypalButton
                      paypalID={paypalID}
                      checkoutCalculator={checkoutCalculator}
                      onSuccess={(order)=>{
                        //console.log('order', order)
                        if(!order) return;
                        order.id = order._id;
                        if(this.props.onSuccess) this.props.onSuccess(order);
                        window.location.href = '/checkout/complete/?paypal=succeeded';
                      }}
                      onFail={(fail)=>{
                        if(this.props.onFail) this.props.onFail(fail);
                        window.location.href = '/checkout/complete/?paypal=failed';
                      }}
                    />
                  )
                }}
              />
            </Column>
          </Row>
        </Container>
      </div>
    );
  }
}

export default WithLocationHook(Index);


