import React from 'react';
import AffirmLogo from 'global/img/payments/affirm_logo_500.png';
import AfterpayLogo from 'global/img/payments/afterpay_logo_500.png';
import CONTENTS from "./CONTENTS";

function Index () {
  const CONTENT = (typeof window === 'undefined') ? CONTENTS["ENGLISH"] : window.GlobalUtil.dangerSetGlobalObjList(CONTENTS[window.Session.Language].CONTENT); 
  return (
    <div id="FinancingWrapper">
      {CONTENT["FinancingWrapperTitle"][0]}
      <div id="BlerbWrapper">
        <div ied="AffirmBlerb" className="blerbWrapper">
          <div className="blerb">
            <div className="logo"><img src={AffirmLogo} alt={"Affirm Logo"} /></div>
            {CONTENT["AffirmBlerb"][0]}
            {CONTENT["AffirmBlerb"][1]}
          </div>
        </div>
        <div id="AfterpayBlerb" className="blerbWrapper">
          <div className="blerb">
            <div className="logo"><img src={AfterpayLogo} alt={"Afterpay Logo"} /></div>
            {CONTENT["AfterpayBlerb"][0]}
            {CONTENT["AfterpayBlerb"][1]}
          </div>
        </div>
      </div>
    </div>
  );
}








export default Index;
      
